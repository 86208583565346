import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "rgba(255, 255, 255, 1)",
    },
    secondary: {
      main: "#0b182f",
    },
    common: {
      white: "#ffffff",
      black: "#000000",
    },
    warning: { main: "#f89622" },
    info: { main: "#135ec5" },
    disabled: { main: "#00000061" },
    grey: { main: "#6a6e77" },
    background: {
      paper: "rgba(31, 42, 60, 1)",
      default: "rgba(31, 42, 60, 1)",
    },
    drawer: {
      main: "#f50057",
    },
    text: {
      primary: "rgba(0, 0, 0, 1)",
      lightgray: "#a8a8a8",
      darkgreen: "#247b83",
    },
  },
  typography: {
    fontFamily: "sans-serif",
    h1: {
      fontWeight: "300",
      fontSize: "96px",
      lineHeight: "112px",
      letterSpacing: "-1.5px",
    },
    h2: {
      fontWeight: "300",
      fontSize: "60px",
      lineHeight: "72px",
      letterSpacing: "-0.5px",
    },
    h3: {
      fontWeight: "700",
      fontSize: "48px",
      lineHeight: "56px",
    },
    h4: {
      fontWeight: "500",
      fontSize: "38px",
      lineHeight: "42px",
      letterSpacing: "0.25px",
    },
    h5: {
      fontWeight: "500",
      fontSize: "35px",
      lineHeight: "40.85px",
    },
    h6: {
      fontWeight: "600",
      fontSize: "20px",
      lineHeight: "30px",
      letterSpacing: "0.25px",
    },
    body1: {
      fontSize: "16px",
      fontWeight: "600",
      lineHeight: "31px",
    },
    body2: {
      fontSize: "14px",
      fontWeight: "600",
      lineHeight: "31px",
    },
  },
  overrides: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          color: "#000000",
        },
      },
    },
  },
});

export default theme;
