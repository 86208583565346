import { Box, Typography } from "@mui/material";

const FeaturesItem = ({ title, content }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems={{ xs: "center", md: "start" }}
    >
      <Box
        component="img"
        src="/Images/home/FI_LOGO.png"
        height={80}
        width={48}
      />
      <Typography variant="h5" color="warning.main" pt={3}>
        {title}
      </Typography>
      <Typography color="secondary.main">{content}</Typography>
    </Box>
  );
};

export default FeaturesItem;
