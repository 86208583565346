import { ThemeProvider } from "@emotion/react";
import theme from "./theme";
import AppRoutes from "./routes";

function App() {
  return (
    <ThemeProvider theme={theme}>
      {/* <CssBaseline /> */}
      <AppRoutes />
    </ThemeProvider>
  );
}

export default App;
