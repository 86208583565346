import { useEffect, useState } from "react";
import {
  AppBar,
  Box,
  Container,
  Drawer,
  IconButton,
  Toolbar,
} from "@mui/material";
import { ArrowDropDown, Menu } from "@mui/icons-material";
import SideBar from "./SideBar";
import { TabStyle, TabsStyle } from "../../theme/customStyle/TabStyle";
import { useLocation, useNavigate } from "react-router-dom";
import { headerTabs } from "../../constants/headerTabs";
import HeaderSubMenu from "./HeaderSubMenu";

const HeaderAppBar = () => {
  const [state, setState] = useState(false);
  const [openHeaderSubMenu, setOpenHeaderSubMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [pageIndex, setPageIndex] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    const index = headerTabs.findIndex((x) => x.path === location.pathname);
    if (index === -1) {
      const innerIndex = headerTabs.findIndex(
        (x) =>
          x.subMenu &&
          x.subMenu.findIndex((y) => y.path === location.pathname) >= 0
      );
      setPageIndex(innerIndex >= 0 ? innerIndex : 0);
    } else setPageIndex(index >= 0 ? index : 0);
  }, [location.pathname]);

  const handleTabClick = (newValue, path, isSidebar) => (event) => {
    setPageIndex(newValue);

    if (!isSidebar) {
      setOpenHeaderSubMenu(true);
      setAnchorEl(event.currentTarget);
    }

    navigate(path);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState(open);
  };

  const handleCloseMenu = () => {
    setOpenHeaderSubMenu(false);
    setAnchorEl(null);
  };

  return (
    <>
      <AppBar
        position="sticky"
        sx={{
          backgroundColor: "secondary.main",
          px: { xs: 2, lg: 32.5 },
          boxShadow: 0,
          top: 0,
        }}
      >
        <Container maxWidth="xl" sx={{ px: { xs: 0, lg: 3 } }}>
          <Toolbar disableGutters>
            <TabsStyle value={pageIndex}>
              {headerTabs.map((tab, index) => (
                <TabStyle
                  key={index}
                  label={tab.name}
                  icon={tab.isSubMenu ? <ArrowDropDown /> : ""}
                  onClick={handleTabClick(index, tab.path, false)}
                />
              ))}
            </TabsStyle>
            {headerTabs[pageIndex].isSubMenu && (
              <HeaderSubMenu
                open={openHeaderSubMenu}
                handleClose={handleCloseMenu}
                anchorEl={anchorEl}
                subMenu={headerTabs[pageIndex].subMenu}
              />
            )}
            <Box
              sx={{
                flexGrow: 1,
                display: {
                  xs: "flex",
                  md: "none",
                },
                justifyContent: "space-between",
                alignItems: "center",
                height: "98px",
              }}
            >
              <Box
                component="img"
                src="/Images/layout/FINITIC.png"
                height="63px"
                width="137px"
                pl={{ xs: 0, lg: 22.88 }}
              />
              <IconButton
                size="large"
                onClick={toggleDrawer(true)}
                color="inherit"
              >
                <Menu color="warning" />
              </IconButton>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      <Drawer anchor="right" open={state} onClose={toggleDrawer(false)}>
        <SideBar
          activeTab={pageIndex}
          toggleDrawer={toggleDrawer}
          handleClick={handleTabClick}
        />
      </Drawer>
    </>
  );
};

export default HeaderAppBar;
