import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import TeamAvatar from "./TeamAvatar";

const MeetOurTeam = () => {
  return (
    <Box display="flex" flexDirection="column" gap={6} py={4}>
      <Box display="flex" flexDirection="column" gap={2}>
        <Typography textAlign="center" variant="h4">
          MEET OUR TEAM
        </Typography>
        <Typography textAlign="center" variant="body2">
          Get to know the faces behind our success. Meet our talented and
          dedicated team of professionals.
        </Typography>
      </Box>
      <Box>
        <Typography lineHeight={0.5} variant="body2" textAlign="center">
          Best team
        </Typography>
        <Typography color="secondary.main" textAlign="center" variant="h6">
          Our teams
        </Typography>
        <Box align="center" py={1}>
          <Divider color="secondary.main" align="center" width={35} />
        </Box>
      </Box>
      <Grid container spacing={2} px={{ xs: 0, lg: 44 }}>
        <Grid item xs={6} md={3}>
          <TeamAvatar title={"Fatma elsaad"} />
        </Grid>
        <Grid item xs={6} md={3}>
          <TeamAvatar title={"Israa hatem"} />
        </Grid>
        <Grid item xs={6} md={3}>
          <TeamAvatar title={"Ahmed osama"} />
        </Grid>
        <Grid item xs={6} md={3}>
          <TeamAvatar title={"Mohap ramadan"} />
        </Grid>
      </Grid>
      <Box display="flex" pt={6} justifyContent="center">
        <Button
          variant="contained"
          color="warning"
          sx={{ color: "common.white" }}
        >
          View All Team
        </Button>
      </Box>
    </Box>
  );
};

export default MeetOurTeam;
