import { Box, Typography } from "@mui/material";
import React from "react";
import { ListItemStyle } from "../../../theme/customStyle/ListItemStyle";
import { ListItemStyle2 } from "../../../theme/customStyle/ListItemStyle2";

const ReportingAndAnalyticsContent = () => {
  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        py={6}
        px={{ xs: 6, lg: 32.5 }}
      >
        <Typography textAlign="center" variant="h4" pb={1}>
          Reporting and Analytics
        </Typography>
        <Typography textAlign="center" variant="h6" pb={1}>
          FOREX CRM PROVIDE COMPREHENSIVE REPORTING AND ANALYTICS.
        </Typography>
        <Typography textAlign="center" variant="body2" fontWeight={500}>
          reporting and analytics provide valuable insights that forex brokers
          can use to make better business decisions, improve customer
          satisfaction, reduce risk, increase profitability, and stay ahead of
          the competition.
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        gap={6}
        backgroundColor="secondary.main"
        py={10}
        px={{ xs: 6, lg: 32.5 }}
      >
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography textAlign="center" color="common.white" variant="h4">
            LET'S GOT TO IT.
          </Typography>
          <Box display="flex" justifyContent="center">
            <Box component="ol">
              <ListItemStyle data-value="1">
                <Typography color="common.white">
                  What is the reporting and analytics in forex CRM?
                </Typography>
              </ListItemStyle>
              <ListItemStyle data-value="2">
                <Typography color="common.white">
                  What are the key reporting and analytics features that a forex
                  CRM should have?
                </Typography>
              </ListItemStyle>
              <ListItemStyle data-value="3">
                <Typography color="common.white">
                  How can a forex CRM meet all regulation requirements?
                </Typography>
              </ListItemStyle>
              <ListItemStyle data-value="4">
                <Typography color="common.white">
                  How can reporting and analytics help forex brokers make better
                  business decisions?
                </Typography>
              </ListItemStyle>
            </Box>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" gap={2}>
          <Typography textAlign="left" color="common.white" variant="h6">
            What is the reporting and analytics in forex CRM?
          </Typography>
          <Typography color="common.white" variant="body2" lineHeight={2.5}>
            Reporting and analytics are crucial components of any forex CRM
            system. They provide valuable insights into the performance of the
            business, allowing brokers to make informed decisions and improve
            their operations.
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" gap={2}>
          <Typography textAlign="left" color="common.white" variant="h6">
            What are the key reporting and analytics features that a forex CRM
            should have?
          </Typography>
          <Typography color="common.white" variant="body2" lineHeight={2.5}>
            One of the key benefits of reporting and analytics in forex CRM is
            the ability to track customer behaviour. By analysing customer data,
            brokers can gain better understanding of their client’s trading
            habits, preferences, and needs. This information can be used to
            tailor marketing campaigns, offer personalized services, and improve
            customer retention.
          </Typography>
          <Typography color="common.white" variant="body2" lineHeight={2.5}>
            Another important aspect of reporting and analytics in forex CRM is
            risk management. By monitoring trading activity and analysing market
            trends, brokers can identify potential risks and take proactive
            measures to mitigate them. This helps to protect both the broker’s
            business and their client’s investments.
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" gap={2}>
          <Typography textAlign="left" color="common.white" variant="h6">
            How can a forex CRM meet all regulation requirements?
          </Typography>
          <Typography color="common.white" variant="body2" lineHeight={2.5}>
            Reporting and analytics also play a critical role in compliance.
            Forex brokers are subject to strict regulations, and accurate
            reporting is essential for maintaining compliance with these
            regulations. By tracking transactions, monitoring account activity,
            and generating detailed reports, brokers can ensure that they are
            meeting all regulatory requirements.
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" gap={2}>
          <Typography textAlign="left" color="common.white" variant="h6">
            How can reporting and analytics help forex brokers make better
            business decisions?
          </Typography>
        </Box>
        <Box>
          <Typography
            textAlign="left"
            variant="body2"
            color="common.white"
            pb={4}
            pl={5}
          >
            Reporting and analytics can help forex brokers make better business
            decisions in the following ways
          </Typography>
          <Box
            component="ol"
            sx={{ paddingInlineStart: "0" }}
            display="flex"
            flexDirection="column"
            gap={2.5}
          >
            <ListItemStyle2 data-value="1">
              <Box display="flex" flexDirection="column" gap={1}>
                <Typography variant="body2" color="common.white">
                  Identifying trends and patterns
                </Typography>
                <Typography variant="body2" color="common.white">
                  By analysing data from various sources, forex brokers can
                  identify trends and patterns that can help them make informed
                  decisions. For example, they can identify which currency pairs
                  are most popular among traders and adjust their offering
                  accordingly.
                </Typography>
              </Box>
            </ListItemStyle2>
            <ListItemStyle2 data-value="2">
              <Box display="flex" flexDirection="column" gap={1}>
                <Typography variant="body2" color="common.white">
                  Understanding customer behaviour
                </Typography>
                <Typography variant="body2" color="common.white">
                  Reporting and analytics can help forex brokers understand how
                  their customers behave, what they like, what they don’t like,
                  and what motivates them to trade. This information can be used
                  to tailor marketing campaigns and improve customer retention.
                </Typography>
              </Box>
            </ListItemStyle2>
            <ListItemStyle2 data-value="3">
              <Box display="flex" flexDirection="column" gap={1}>
                <Typography variant="body2" color="common.white">
                  Improving risk management
                </Typography>
                <Typography variant="body2" color="common.white">
                  Forex brokers deal with a lot of risk daily. Reporting and
                  analytics can help them identify potential risks before they
                  become problems. For example, by monitoring trading activity,
                  brokers can detect unusual trading patterns that may indicate
                  fraud or other illegal activities.
                </Typography>
              </Box>
            </ListItemStyle2>
            <ListItemStyle2 data-value="4">
              <Box display="flex" flexDirection="column" gap={1}>
                <Typography variant="body2" color="common.white">
                  Optimizing pricing strategies
                </Typography>
                <Typography variant="body2" color="common.white">
                  Forex brokers need to price their services competitively to
                  attract customers while still making a profit. Reporting and
                  analytics can help them analyse market trends and competitor
                  pricing strategies to determine the optimal pricing strategy
                  for their business.
                </Typography>
              </Box>
            </ListItemStyle2>
            <ListItemStyle2 data-value="5">
              <Box display="flex" flexDirection="column" gap={1}>
                <Typography variant="body2" color="common.white">
                  Enhancing operational efficiency
                </Typography>
                <Typography variant="body2" color="common.white">
                  By analysing data related to their operations, forex brokers
                  can identify areas where they can improve efficiency and
                  reduce costs. For example, they may discover that certain
                  processes are taking longer that necessary or that certain
                  resources are being underutilized
                </Typography>
                <Typography variant="body2" color="common.white">
                  Overall, reporting and analytics are essential components of
                  any successful forex CRM system. They provide valuable
                  insights into customer behaviour, risk management, compliance,
                  and overall business performance. By leveraging these insights
                  effectively, brokers can improve their operations, increase
                  profitability, and deliver better outcomes for their clients.
                </Typography>
              </Box>
            </ListItemStyle2>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ReportingAndAnalyticsContent;
