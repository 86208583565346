import { Box, Typography } from "@mui/material";
import ForexCrm from "../components/Common/ForexCrm";
import ContactUs from "../components/Layout/ContactUs";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import { Helmet } from "react-helmet-async";

const PrivacyPolicy = () => {
  return (
    <>
      <Helmet>
        <title>Privacy Policy</title>
        <meta name="description" content="Privacy Policy" />
      </Helmet>
      <Box
        sx={{
          backgroundImage: "url(/Images/aboutUs/WHO_WE_ARE.png)",
          backgroundRepeat: "no-repeat",
          backgroundsize: "cover",
          backgroundPosition: "bottom",
          backgroundAttachment: "fixed",
        }}
      >
        <Header isHome={false} />
        <Box py={12} px={{ xs: 12, lg: 32.5 }}>
          <Box>
            <Typography textAlign="center" variant="h6">
              PRIVACY POLICY
            </Typography>
            <Typography pt={2} textAlign="justify" font-family="sans-serif">
              This Privacy Policy sets out how Finitic uses and protects any
              information that you provide when you use this website. For
              information about the cookies we use, and how to manage the data
              that our cookies collect,
            </Typography>
            <Typography pt={2} textAlign="justify" font-family="sans-serif">
              This Privacy Policy covers our treatment of confidential and
              personally identifiable information collected from Merchants who
              apply to our payment gateway services through this website.
            </Typography>
            <Typography pt={2} textAlign="justify" font-family="sans-serif">
              We respect your privacy and want to promote trust and confidence
              on the internet. As such, we feel it is necessary to disclose to
              you what information this site collects and tracks and what we do
              with the information that we collect.
            </Typography>
            <Typography pt={2} textAlign="justify" font-family="sans-serif">
              The number of employees who can access this information and are
              involved in the management of this information is limited and is
              on a need-to-know basis only. We use advanced security technology
              to prevent our computers from being accessed by unauthorized
              persons. It is important for you to protect against unauthorized
              access to your Login ID/password and to your computer. Be sure to
              protect the password used to access our services
            </Typography>
            <Typography pt={2} textAlign="justify" font-family="sans-serif">
              Finitic is committed to ensuring that your privacy is protected.
              Should we ask you to provide certain information by which you can
              be identified when using this website, then you can be assured
              that it will only be used in accordance with this privacy
              statement.
            </Typography>
            <Typography pt={2} textAlign="justify" font-family="sans-serif">
              Finitic may change this policy from time to time by updating this
              page. You should check this page from time to time to ensure that
              you are happy with any changes
            </Typography>
          </Box>
          <Box>
            <Typography textAlign="center" variant="h6">
              SECURITY
            </Typography>
            <Typography pt={2} textAlign="justify" font-family="sans-serif">
              We are committed to ensuring that your information is secure. To
              prevent unauthorized access or disclosure we have put in place
              suitable physical, electronic, and managerial procedures to
              safeguard and secure the information we collect online.
            </Typography>
            <Typography pt={2} textAlign="justify" font-family="sans-serif">
              We will not sell, distribute, or lease your personal information
              to third parties unless we have your permission or are required by
              law to do so. We may use your personal information to send you
              promotional information about third parties which we think you may
              find interesting if you tell us that you wish this to happen.
            </Typography>
          </Box>
          <Box>
            <Typography textAlign="center" variant="h6">
              WHAT WE COLLECT
            </Typography>
            <Typography pt={2} textAlign="justify" font-family="sans-serif">
              We may collect the following information and documentation: <br />
              1- Name and job title. <br />
              2-Contact information including email address.
              <br />
              3-Demographic information such as postcode, preferences, and
              interests. <br />
              4-Other information and documentation relevant to your application
              for our payment services.
            </Typography>
          </Box>
          <Box>
            <Typography textAlign="center" variant="h6">
              WHAT WE DO WITH THE INFORMATION WE GATHER?
            </Typography>
            <Typography pt={2} textAlign="justify" font-family="sans-serif">
              We require this information to understand your needs and provide
              you with a better service, and in-particular for the following
              reasons: <br />
              1-Internal record keeping.
              <br />
              2-Assess the qualification of your application. <br />
              3-Internal compliance checks. <br />
              4-External compliance checks by certified vendors.
              <br />
              5-We may submit your application to Acquiring Banks for approval.
              <br />
              6-We may use the information to improve our products and services.{" "}
              <br />
              7-We may periodically send promotional emails about new products,
              special offers or other information which we think you may find
              interesting using the email address which you have provided. From
              time to time, we may also use your information to contact you for
              market research purposes. <br />
              8-We may contact you by email, phone, fax or mail. <br />
              9-We may use this information to customize the website according
              to your interests. <br />
              10-Upon appropriate request or order, we will disclose the
              required information / documentation in a secure manner with the
              acquiring banks, Card Association, court, government authorities
              or law enforcement agencies. Reporting in compliance with
              applicable rules and regulations.
            </Typography>
          </Box>
          <Box>
            <Typography textAlign="center" variant="h6">
              CONTROLLING YOUR PERSONAL INFORMATION
            </Typography>
            <Typography pt={2} textAlign="justify" font-family="sans-serif">
              We use reasonable precautions to keep confidential and personal
              information disclosed to us secure and do not disclose this
              information to other individuals or organizations unless required
              by law. In the case of abuse or breach of security, we are not
              responsible for any breach of security or for any actions of any
              third parties which receive the information illegally. <br />
              We may send personally identifiable information about you to other
              companies, when we need to share your information to provide the
              product or service you have requested. <br />
              We will not distribute customer information to be used in mailing
              lists, surveys ,or any other purpose other than what is required
              to perform our services. <br />
              If you choose to restrict the collection or use of your
              confidential and personal information “Then Kindly Do Not Proceed
              & Exit Our Website”
            </Typography>
          </Box>
          <Box>
            <Typography textAlign="center" variant="h6">
              HOW WE USE COOKIES
            </Typography>
            <Typography pt={2} textAlign="justify" font-family="sans-serif">
              Please visit our Cookie Notice for information about our use of
              cookies, and how you can manage the data that our cookies collect.
            </Typography>
          </Box>
          <Box>
            <Typography textAlign="center" variant="h6">
              Links to other websites LINKS TO OTHER WEBSITES
            </Typography>
            <Typography pt={2} textAlign="justify" font-family="sans-serif">
              Our website may contain links to other websites of interest.
              However, once you have used these links to leave our site, you
              should note that we do not have any control over that other
              website. Therefore, we cannot be responsible for the protection
              and privacy of any information which you provide whilst visiting
              such sites and such sites are not governed by this privacy
              statement. You should exercise caution and look at the privacy
              statement applicable to the website in question.
              <br />
              If you have any queries or issues pertaining to your information
              or our privacy policy, then do write to us on info@finitic.com.
            </Typography>
          </Box>
        </Box>
        <ForexCrm />
        <ContactUs backgroundImage="home/FOOTER_BANNER.png" />
        <Footer />
      </Box>
    </>
  );
};

export default PrivacyPolicy;
