import { Helmet } from "react-helmet-async";
import WhoWeAre from "../components/AboutUs/WhoWeAre";
import ForexCrm from "../components/Common/ForexCrm";
import ContactUs from "../components/Layout/ContactUs";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";

const AboutUs = () => {
  return (
    <>
      <Helmet>
        <title>About Us</title>
        <meta name="description" content="About Us" />
      </Helmet>
      <Header isHome={false} />
      <WhoWeAre />
      <ForexCrm />
      <ContactUs backgroundImage="home/FOOTER_BANNER.png" />
      <Footer />
    </>
  );
};

export default AboutUs;
