import { Box, Button, Typography } from "@mui/material";
import { MailOutline, Phone } from "@mui/icons-material";
import HeaderAppBar from "./HeaderAppBar";
import { useGetMediaQueryUp } from "../../hooks/useGetMediaQuery";
//import PopupForum from "./PopUpForm/PopupForm";

const Header = ({ isHome }) => {
  const isMdUp = useGetMediaQueryUp("md");

  return (
    <>
      {!isMdUp && <HeaderAppBar />}
      <Box sx={{ backgroundColor: "secondary.main" }}>
        {isMdUp && (
          <Box
            px={{ xs: 4.75, lg: 9.5 }}
            height="98px"
            display="flex"
            alignItems="flex-end"
            justifyContent="space-between"
          >
            <>
              <Box
                component="img"
                src="/Images/layout/FINITIC.png"
                height="63px"
                width="137px"
                pl={{ xs: 0, lg: 22.88 }}
              />
              <Box display="flex" gap={7.5} alignItems="center">
                <Box display="flex" gap={2.25} alignItems="center">
                  <MailOutline color="warning" />
                  <Typography color="text.lightgray">
                    info@finitic.com
                  </Typography>
                </Box>
                <Box display="flex" gap={2.25} alignItems="center">
                  <Phone color="warning" />
                  <Typography color="text.lightgray">+971 45689835</Typography>
                </Box>
                <Button
                    variant="contained"
                    color="primary"
                    sx={{
                    borderRadius: 5.25,
                    }}
                >
                    <Typography color="info.main"> <a style={{ textDecoration: "none", color: "inherit" }} href="#contact-form">Live Demo </a> </Typography>
                </Button>               
              </Box>
            </>
          </Box>
        )}
        <Box display="flex" flexDirection="column" pb={isMdUp ? 0 : 6} px={2}>
          {!isHome && (
            <>
              <Typography
                variant="h3"
                color="text.lightgray"
                textAlign="center"
                fontWeight={500}
                pt={6}
              >
                COMPANY
              </Typography>
              <Typography variant="h3" color="primary.main" textAlign="center">
                PROFILE
              </Typography>
            </>
          )}
          {isHome && (
            <>
              <Box
                component="img"
                src="/Images/layout/FOREX_CAPITAL_BANNER.png"
              />
              <Typography color="text.lightgray" textAlign="center">
                A multi-module CRM - Forex Back-office. Forex Trader's Room &
                Multi-Level IB pane
              </Typography>
            </>
          )}
        </Box>
      </Box>
      {isMdUp && <HeaderAppBar />}
    </>
  );
};

export default Header;
