import { Box, Typography } from "@mui/material";
import React from "react";
import { ListItemStyle } from "../../../theme/customStyle/ListItemStyle";
import { ListItemStyle2 } from "../../../theme/customStyle/ListItemStyle2";

const Mt4AndMt5Content = () => {
  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        py={6}
        px={{ xs: 6, lg: 32.5 }}
      >
        <Typography textAlign="center" variant="h4" pb={1}>
          MetaTrader 4 and MetaTrader 5
        </Typography>
        <Typography textAlign="center" variant="h6" pb={1}>
          BROKERS USE METATRADER 4/5 TO MAXIMIZE EFFICIENCY.
        </Typography>
        <Typography textAlign="center" variant="body2" fontWeight={500}>
          MetaTrader 4/5 is a popular trading platform used by brokers in the
          forex market.
        </Typography>
        <Typography textAlign="center" variant="body2" fontWeight={500}>
          We will provide valuable insights into the world of MetaTrader 4/5 for
          brokers in the forex market.
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        gap={6}
        backgroundColor="secondary.main"
        py={4}
        px={{ xs: 6, lg: 32.5 }}
      >
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography textAlign="center" color="common.white" variant="h4">
            LET'S GOT TO IT.
          </Typography>
          <Box display="flex" justifyContent="center">
            <Box component="ol">
              <ListItemStyle data-value="1">
                <Typography color="common.white">
                  What is the MetaTrader 4/5 for forex brokers?
                </Typography>
              </ListItemStyle>
              <ListItemStyle data-value="2">
                <Typography color="common.white">
                  What are the benefits of using MetaTrader 4/5 in a forex CRM
                  for broker?
                </Typography>
              </ListItemStyle>
              <ListItemStyle data-value="3">
                <Typography color="common.white">
                  Can broker customize MetaTrader 4/5 to fit their specific
                  needs in a forex CRM?
                </Typography>
              </ListItemStyle>
              <ListItemStyle data-value="4">
                <Typography color="common.white">
                  How does MetaTrader 4/5 help brokers manage their client’s
                  accounts and trades in a forex CRM?
                </Typography>
              </ListItemStyle>
              <ListItemStyle data-value="5">
                <Typography color="common.white">
                  How does the use of MetaTrader 4/5 impact the overall user
                  experience for clients of forex brokers?
                </Typography>
              </ListItemStyle>
            </Box>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" gap={2}>
          <Typography textAlign="left" color="common.white" variant="h6">
            What is the MetaTrader 4/5 for forex brokers?
          </Typography>
          <Typography color="common.white" variant="body2" lineHeight={2.5}>
            MetaTrader 4/5 offers a range of features that make it an ideal
            choice for brokers looking to provide their clients with a reliable
            and efficient trading experience. One of the key benefits of
            MetaTrader 4/5 is its compatibility with forex CRM system.
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" gap={2}>
          <Typography textAlign="left" color="common.white" variant="h6">
            What are the benefits of using MetaTrader 4/5 in a forex CRM for
            broker?
          </Typography>
          <Typography color="common.white" variant="body2" lineHeight={2.5}>
            Forex CRM systems are designed to help brokers manage their client
            relationships more effectively. They provide a range of tools and
            features that enable brokers to track client activity, monitor
            performance, and communication with client in real-time. When
            integrate MetaTrader 4/5, these systems can provide even greater
            benefits for brokers
          </Typography>
          <Typography color="common.white" variant="body2" lineHeight={2.5}>
            One of the main advantages of using MetaTrader 4/5 in conjunction
            with a forex CRM system is the ability to automate many tasks. This
            can include everything from account creation and management to trade
            execution and reporting. By automating this process, brokers can
            save time and reduce the risk of errors or delays
          </Typography>
          <Typography color="common.white" variant="body2" lineHeight={2.5}>
            Another benefit of using MetaTrader 4/5 in forex CRM for brokers is
            the ability to offer clients a more personalized experience. By
            tracking client activity and preferences, brokers can tailor their
            services to meet individual needs and preferences. This can help
            build stronger relationships with clients and increase customer
            loyalty over time
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" gap={2}>
          <Typography textAlign="left" color="common.white" variant="h6">
            Can broker customize MetaTrader 4/5 to fit their specific needs in a
            forex CRM?
          </Typography>
          <Typography color="common.white" variant="body2" lineHeight={2.5}>
            Yes, brokers can customize MetaTrader 4/5 to fit their specific
            needs in a forex CRM. MetaTrader 4/5 is a highly customizable
            platform that allows brokers to add custom indicators, scripts, and
            expert advisors to enhance their trading experience. Additionally,
            brokers can integrate third-party plugins and tools to extend the
            functionality of the platform. This flexibility makes MetaTrader 4/5
            an ideal choice for brokers who want to tailor their trading
            environment to meet their specific needs.
          </Typography>
        </Box>
        <Box>
          <Typography
            textAlign="left"
            color="common.white"
            variant="h6"
            pb={6}
            pl={5}
          >
            How does MetaTrader 4/5 help brokers manage their client’s accounts
            and trades in a forex CRM
          </Typography>
          <Typography
            textAlign="left"
            variant="body2"
            color="common.white"
            pb={4}
            pl={5}
          >
            MetaTrader 4/5 provides several features that help brokers manage
            their client’s accounts and trades in a forex CRM, including:
          </Typography>
          <Box
            component="ol"
            sx={{ paddingInlineStart: "0" }}
            display="flex"
            flexDirection="column"
            gap={2.5}
          >
            <ListItemStyle2 data-value="1">
              <Box display="flex" flexDirection="column" gap={1}>
                <Typography variant="body2" color="common.white">
                  Account Management
                </Typography>
                <Typography variant="body2" color="common.white">
                  MetaTrader 4/5 allows brokers to manage multiple client
                  accounts from a single platform. This makes it easier for
                  brokers to monitor their client’s trading activity, account
                  balances, and other important information.
                </Typography>
              </Box>
            </ListItemStyle2>
            <ListItemStyle2 data-value="2">
              <Box display="flex" flexDirection="column" gap={1}>
                <Typography variant="body2" color="common.white">
                  Trading Tools
                </Typography>
                <Typography variant="body2" color="common.white">
                  MetaTrader 4/5 provides a range of trading tools that help
                  brokers manage their client’s trades. These tools include
                  real-time charts, technical indicators, and automated trading
                  system
                </Typography>
              </Box>
            </ListItemStyle2>
            <ListItemStyle2 data-value="3">
              <Box display="flex" flexDirection="column" gap={1}>
                <Typography variant="body2" color="common.white">
                  Risk management
                </Typography>
                <Typography variant="body2" color="common.white">
                  MetaTrader 4/5 provides risk management tools that helps
                  brokers manage the risks associated with their client’s
                  trades. These tools include stop-loss orders, take-profit
                  orders, and margin requirements.
                </Typography>
              </Box>
            </ListItemStyle2>
            <ListItemStyle2 data-value="4">
              <Box display="flex" flexDirection="column" gap={1}>
                <Typography variant="body2" color="common.white">
                  Reporting
                </Typography>
                <Typography variant="body2" color="common.white">
                  MetaTrader 4/5 provides detailed reporting features that allow
                  brokers to track their client’s trading activity and generate
                  reports on performance, profitability, and other key metrics.
                </Typography>
              </Box>
            </ListItemStyle2>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" gap={2}>
          <Typography textAlign="left" color="common.white" variant="h6">
            How does the use of MetaTrader 4/5 impact the overall user
            experience for clients of forex brokers?
          </Typography>
          <Typography color="common.white" variant="body2" lineHeight={2.5}>
            The use of MetaTrader 4/5 can significantly impact the overall user
            experiences for clients of forex brokers. MetaTrader is a popular
            trading platform that offers advanced charting tools, technical
            analysis indicators, and automated trading capabilities. The
            platform is user-friendly and easy to navigate, making it an ideal
            choice for both novice and experienced traders. The use of
            MetaTrader 4/5 can enhance the user experience for clients of forex
            brokers by providing them with a powerful and versatile trading
            platform that is easy to use and customize.
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default Mt4AndMt5Content;
