import { Box, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import Header from "../components/Layout/Header";
import BrokerManagemant from "../components/Home/BrokerManagemant";
import BackOfficePanel from "../components/Home/BackOfficePanel";
import BrokerFocused from "../components/Home/BrokerFocused";
import Overview from "../components/Home/Overview";
import Features from "../components/Home/Features";
import PartnerIbPortal from "../components/Home/PartnerIbPortal";
import TradersRoom from "../components/Home/TradersRoom";
import ContactUs from "../components/Layout/ContactUs";
import Footer from "../components/Layout/Footer";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

const Home = () => {
  const { product } = useParams();
  useEffect(() => {
    if (product) {
      setTimeout(() => {
        document.querySelector("#product").scrollIntoView({
          behavior: "smooth",
        });
      }, 200);
    }
  }, [product]);

  return (
    <>
      <Helmet>
        <title>Home</title>
        <meta name="description" content="Home" />
      </Helmet>
      <Header isHome={true} />
      <Box display="flex" flexDirection="column" gap={12}>
        <BrokerManagemant />
        <BrokerFocused />
        <BackOfficePanel />
        <PartnerIbPortal />
        <TradersRoom />
        <Overview />
        <Features />
        <Box
          px={{ xs: 6, lg: 32.5 }}
          py={12}
          sx={{ backgroundColor: "grey.main" }}
        >
          <Typography variant="h2" textAlign="center" color="primary.main" id="contact-form">
            Most intuitive & rich features packed Forex CRM ever built for Forex
            Broker
          </Typography>
        </Box>
      </Box>
      <ContactUs backgroundImage="home/FOOTER_BANNER.png" />
      <Footer />
    </>
  );
};

export default Home;
