import { Helmet } from "react-helmet-async";
import ForexCrm from "../components/Common/ForexCrm";
import ContactUs from "../components/Layout/ContactUs";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import MeetOurTeam from "../components/OurTeams/MeetOurTeam";

const OurTeam = () => {
  return (
    <>
      <Helmet>
        <title>Our Team</title>
        <meta name="description" content="Our Team" />
      </Helmet>
      <Header isHome={false} />
      <MeetOurTeam />
      <ForexCrm />
      <ContactUs backgroundImage="home/FOOTER_BANNER.png" />
      <Footer />
    </>
  );
};

export default OurTeam;
