import styled from "@emotion/styled";

export const ListItemStyle = styled("li")({
  listStyle: "none",
  display: "flex",
  alignItems: "baseline",
  paddingBottom: "16px",
  "&::before": {
    content: "attr(data-value)",
    fontWeight: 650,
    fontSize: 20,
    backgroundColor: "white",
    color: "#f89622",
    padding: "0px 5px 2px 5px ",
    marginRight: 22,
    lineHeight: 1,
  },
});
