import { Box, Button, Grid, Typography } from "@mui/material";

const ClientPortal = () => {
  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <Grid container spacing={6}>
        <Grid item md={12} lg={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 5,
              justifyContent: "center",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <Typography color="text.lightgray" variant="h5">
                Client Portal
              </Typography>
              <Typography color="text.lightgray" variant="body2">
                Presenting our revolutionary Fintech CRM System Client Portal,
                meticulously crafted to cater to every facet of administrative
                finesse and support excellence in forex brokerage management.
                With an unwavering focus on empowering your operations, our
                platform offers a suite of unparalleled features. Effortlessly
                handle client accounts, seamlessly process deposits and
                withdrawals, monitor trades with precision, ensure unwavering
                compliance adherence, effortlessly generate comprehensive
                reports, and maintain a vigilant eye on risk management. The
                backbone of a seamlessly functioning forex brokerage, our system
                stands as a beacon of efficient back-office operations, ensuring
                clients experience the epitome of efficient and dependable
                service.
              </Typography>
            </Box>
            <Box>
              <Button
                variant="contained"
                color="warning"
                sx={{
                  borderRadius: 2,
                }}
              >
                <Typography
                  sx={{
                    textAlign: "center",
                  }}
                  color="primary.main"
                >
                  Live Demo
                </Typography>
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item md={12} lg={6} display="flex" alignItems="center">
          <Box
            component="img"
            src="/Images/home/Client_Portal.png"
            sx={{ width: "100%", borderRadius: 2 }}
            boxShadow={20}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ClientPortal;
