import { Helmet } from "react-helmet-async";
import CustomizingForexCrmContent from "../../components/Blogs/CustomizingForexCrm";
import ContactUs from "../../components/Layout/ContactUs";
import Footer from "../../components/Layout/Footer";
import Header from "../../components/Layout/Header";

const CustomizingForexCrm = () => {
  return (
    <>
      <Helmet>
        <title>Customizing Forex Crm</title>
        <meta name="description" content="Customizing Forex Crm" />
      </Helmet>
      <Header isHome={false} />
      <CustomizingForexCrmContent />
      <ContactUs backgroundImage="blogs/CONTACTUS_BANNER2.png" />
      <Footer />
    </>
  );
};

export default CustomizingForexCrm;
