import { Helmet } from "react-helmet-async";
import Mt4AndMt5Content from "../../components/Blogs/Mt4AndMt5";
import ContactUs from "../../components/Layout/ContactUs";
import Footer from "../../components/Layout/Footer";
import Header from "../../components/Layout/Header";

const Mt4andMt5 = () => {
  return (
    <>
      <Helmet>
        <title>Mt4 And Mt5</title>
        <meta name="description" content="Mt4 And Mt5" />
      </Helmet>
      <Header isHome={false} />
      <Mt4AndMt5Content />
      <ContactUs backgroundImage="blogs/CONTACTUS_BANNER2.png" />
      <Footer />
    </>
  );
};

export default Mt4andMt5;
