import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import CRM from "./CRM";
import { useEffect, useState } from "react";
import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import ClientPortal from "./ClientPortal";
import IbPortal from "./IbPortal";
import Gold from "./Gold";
import { useParams } from "react-router-dom";
import HybridCRM from "./HybridCRM";
import { useGetMediaQueryUp } from "../../../hooks/useGetMediaQuery";

const Overview = () => {
  const isMdUp = useGetMediaQueryUp("sm");
  const { product } = useParams();

  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = 5;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    if (product === "crm-system") {
      setActiveStep(0);
    } else if (product === "client-portal") {
      setActiveStep(1);
    } else if (product === "ib-portal") {
      setActiveStep(2);
    } else if (product === "physical-gold") {
      setActiveStep(3);
    } else if (product === "hybrid-crm") {
      setActiveStep(4);
    }
  }, [product]);

  return (
    <Box
      // px={{ xs: 6, lg: 32.5 }}
      display="flex"
      flexDirection="column"
      gap={6}
      sx={{ backgroundColor: "secondary.main" }}
      py={12}
      id="product"
    >
      <Box
        display="flex"
        flexDirection="column"
        gap={1}
        px={{ xs: 2, lg: 32.5 }}
      >
        {isMdUp ? (
          <Typography variant="h3" textAlign="center" color="primary.main">
            Overview
          </Typography>
        ) : (
          <Typography variant="h5" textAlign="center" color="primary.main">
            Overview
          </Typography>
        )}

        <Typography textAlign="center" color="text.lightgray">
          Everything you need to excel in Forex business has been integrated in
          Forex CRM
        </Typography>
      </Box>
      <Box display="flex" gap={3} px={{ xs: 2, lg: 32.5 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} lg={2.4}>
            <Button
              fullWidth
              variant={activeStep === 0 ? "contained" : "outlined"}
              color="grey"
              sx={{ borderRadius: 2 }}
              onClick={() => setActiveStep(0)}
            >
              <Typography
                color={activeStep === 0 ? "warning.main" : "primary.main"}
              >
                CRM System
              </Typography>
            </Button>
          </Grid>
          <Grid item xs={12} md={4} lg={2.4}>
            <Button
              fullWidth
              variant={activeStep === 1 ? "contained" : "outlined"}
              color="grey"
              sx={{ borderRadius: 2 }}
              onClick={() => setActiveStep(1)}
            >
              <Typography
                color={activeStep === 1 ? "warning.main" : "primary.main"}
              >
                Client Portal
              </Typography>
            </Button>
          </Grid>
          <Grid item xs={12} md={4} lg={2.4}>
            <Button
              fullWidth
              variant={activeStep === 2 ? "contained" : "outlined"}
              color="grey"
              sx={{ borderRadius: 2 }}
              onClick={() => setActiveStep(2)}
            >
              <Typography
                color={activeStep === 2 ? "warning.main" : "primary.main"}
              >
                IB Portal
              </Typography>
            </Button>
          </Grid>
          <Grid item xs={12} md={4} lg={2.4}>
            <Button
              fullWidth
              variant={activeStep === 3 ? "contained" : "outlined"}
              color="grey"
              sx={{ borderRadius: 2 }}
              onClick={() => setActiveStep(3)}
            >
              <Typography
                color={activeStep === 3 ? "warning.main" : "primary.main"}
              >
                Physical Gold
              </Typography>
            </Button>
          </Grid>
          <Grid item xs={12} md={4} lg={2.4}>
            <Button
              fullWidth
              variant={activeStep === 4 ? "contained" : "outlined"}
              color="grey"
              sx={{ borderRadius: 2 }}
              onClick={() => setActiveStep(4)}
            >
              <Typography
                color={activeStep === 4 ? "warning.main" : "primary.main"}
              >
                Hybrid CRM
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: { lg: "row", xs: "column" },
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Grid container alignItems="center">
            <Grid item xs={2}>
              <Box display="flex" justifyContent="center" width="100%">
                <IconButton
                  disabled={activeStep === 0}
                  color="secondary"
                  onClick={handleBack}
                  sx={{
                    backgroundColor: "primary.main",
                    "&:hover": {
                      backgroundColor: "primary.main",
                    },
                    "&.Mui-disabled": {
                      color: "grey.main",
                      backgroundColor: "primary.main",
                    },
                  }}
                >
                  <ArrowLeft />
                </IconButton>
              </Box>
            </Grid>
            <Grid item xs={8}>
              {activeStep === 0 && <CRM />}
              {activeStep === 1 && <ClientPortal />}
              {activeStep === 2 && <IbPortal />}
              {activeStep === 3 && <Gold />}
              {activeStep === 4 && <HybridCRM />}
            </Grid>
            <Grid item xs={2}>
              <Box display="flex" justifyContent="center" width="100%">
                <IconButton
                  disabled={activeStep === maxSteps - 1}
                  color="secondary"
                  onClick={handleNext}
                  sx={{
                    backgroundColor: "primary.main",
                    "&:hover": {
                      backgroundColor: "primary.main",
                    },
                    "&.Mui-disabled": {
                      color: "grey.main",
                      backgroundColor: "primary.main",
                    },
                  }}
                >
                  <ArrowRight />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default Overview;
