import { Avatar, Box, Typography } from "@mui/material";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

const TeamAvatar = ({ title }) => {
  return (
    <div>
      <Box display="flex" justifyContent="center" gap={6}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Avatar sx={{ width: 96, height: 96 }} />
          <Typography color="secondary.main" textAlign="center" variant="body2">
            {title}
          </Typography>
          <Box display="flex" alignItems="center">
            <TwitterIcon fontSize="small" color="warning" />
            <Box
              component="img"
              src="/Images/layout/FB.png"
              height="18px"
              sx={{
                filter:
                  " invert(79%) sepia(29%) saturate(7165%) hue-rotate(345deg) brightness(103%) contrast(94%)",
              }}
            />
            <InstagramIcon fontSize="small" color="warning" />
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default TeamAvatar;
