import { Box, Grid, Typography } from "@mui/material";
import BackOfficePanelItem from "./BackOfficePanelItem";
import { useGetMediaQueryUp } from "../../../hooks/useGetMediaQuery";

const BackOfficePanel = () => {
  const isMdUp = useGetMediaQueryUp("md");

  return (
    <Box px={{ xs: 2, lg: 32.5 }} display="flex" flexDirection="column" gap={6}>
      <Box display="flex" flexDirection="column" gap={2}>
        {isMdUp ? (
          <Typography variant="h3" textAlign="center" color="secondary.main">
            Broker-focused CRM
          </Typography>
        ) : (
          <Typography variant="h5" textAlign="center" color="secondary.main">
            Broker-focused CRM
          </Typography>
        )}
        <Typography variant="h5" textAlign="center" color="text.darkgreen">
          Every component of our CRM system is developed for the need of a Forex
          broker.
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: { lg: "row", xs: "column" },
        }}
      >
        <Grid container spacing={8}>
          <Grid item md={12} lg={6} display="flex" alignItems="center">
            <Box
              component="img"
              src="/Images/home/CRM_Request.png"
              sx={{ width: "100%", borderRadius: 2 }}
              boxShadow={20}
            />
          </Grid>
          <Grid item md={12} lg={6}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                justifyContent: "center",
              }}
            >
              <Box width="100%">
                {isMdUp ? (
                  <Typography
                    variant="h4"
                    sx={{
                      textAlign: { lg: "left", xs: "center" },
                    }}
                  >
                    Back Office Panel
                  </Typography>
                ) : (
                  <Typography
                    variant="h5"
                    sx={{
                      textAlign: { lg: "left", xs: "center" },
                    }}
                  >
                    Back Office Panel
                  </Typography>
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 3,
                  justifyContent: "center",
                }}
              >
                <BackOfficePanelItem
                  title="Easy Client Management"
                  content="Every component of our CRM system is developed for the need of a Forex broker."
                  fontColor="text.darkgreen"
                  imageColor="warning.main"
                />
                <BackOfficePanelItem
                  title="Easy Client Management"
                  content="Every component of our CRM system is developed for the need of a Forex broker."
                  fontColor="text.darkgreen"
                  imageColor="warning.main"
                />
                <BackOfficePanelItem
                  title="Easy Client Management"
                  content="Every component of our CRM system is developed for the need of a Forex broker."
                  fontColor="text.darkgreen"
                  imageColor="warning.main"
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default BackOfficePanel;
