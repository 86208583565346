import { Helmet } from "react-helmet-async";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Home from "../pages/Home";
import AboutUs from "../pages/AboutUs";
import ContactUs from "../components/ContactUs";
import OurTeam from "../pages/OurTeam";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import ImportantOfForex from "../pages/Blogs/ImportantOfForex";
import CustomizingForexCrm from "../pages/Blogs/CustomizingForexCrm";
import Mt4andMt5 from "../pages/Blogs/Mt4andMt5";
import ReportingAndAnalytics from "../pages/Blogs/ReportingAndAnalytics";

const AppRoutes = () => {
  const defaultPath = "/home";

  return (
    <>
      <Helmet titleTemplate={`%s - Finitic`} defaultTitle={"Finitic"}>
        <meta name="description" content={"Meta Content"} />
      </Helmet>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate to={defaultPath} />} />
          <Route path="/home" element={<Home />} />
          <Route path="/product/:product" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/our-team" element={<OurTeam />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route
            path="/blogs/customizing-forex-crm"
            element={<CustomizingForexCrm />}
          />
          <Route path="/blogs/mt4-and-mt5" element={<Mt4andMt5 />} />
          <Route
            path="/blogs/reporting-and-analytics"
            element={<ReportingAndAnalytics />}
          />
          <Route
            path="/blogs/important-of-forex"
            element={<ImportantOfForex />}
          />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default AppRoutes;
