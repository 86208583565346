import { Box, Typography } from "@mui/material";
import { ListItemStyle } from "../../../theme/customStyle/ListItemStyle";
import { ListItemStyle2 } from "../../../theme/customStyle/ListItemStyle2";

const CustomizingForexCrmContent = () => {
  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        py={6}
        px={{ xs: 6, lg: 32.5 }}
      >
        <Typography textAlign="center" variant="h4" pb={1}>
          CUSTOMIZING A FOREX CRM
        </Typography>
        <Typography textAlign="center" variant="body2" fontWeight={500}>
          Customizing a forex CRM system is an important step for brokers who
          want to improve their services and stay ahead of the competition.
          <br /> By tailoring their CRM system to meet their specific needs,
          brokers can streamline processes, improve efficiency, and provide a
          better experience for their clients.
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        gap={6}
        backgroundColor="secondary.main"
        py={4}
        px={{ xs: 6, lg: 32.5 }}
      >
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography textAlign="center" color="common.white" variant="h4">
            LET'S GOT TO IT.
          </Typography>
          <Box display="flex" justifyContent="center">
            <Box component="ol">
              <ListItemStyle data-value="1">
                <Typography color="common.white">
                  What is the forex CRM customization?
                </Typography>
              </ListItemStyle>
              <ListItemStyle data-value="2">
                <Typography color="common.white">
                  What are the benefits of customizing forex CRM for brokers?
                </Typography>
              </ListItemStyle>
              <ListItemStyle data-value="3">
                <Typography color="common.white">
                  How can forex brokers customize their forex CRM?
                </Typography>
              </ListItemStyle>
            </Box>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" gap={2}>
          <Typography textAlign="left" color="common.white" variant="h6">
            What is the forex CRM Customization?
          </Typography>
          <Typography color="common.white" variant="body2" lineHeight={2.5}>
            Forex CRM customization is the process of tailoring a customer
            relationship management system to meet the specific needs of a forex
            brokerage. This involves modifying the software to fit the unique
            requirements of the business, such ad adding custom fields,
            integrated third-party tools, and creating personalized workflo
            Customization is essential for forex brokerages because they have
            unique needs that cannot be met by off-the-shelf CRM solutions. For
            example, forex brokers need to manage complex trading accounts,
            monitor market data in real-time, and provide personalized support
            to their clients. These requirements cannot be met by a generic CRM
            system.
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" gap={2}>
          <Typography textAlign="left" color="common.white" variant="h6">
            What are the benefits of customizing forex CRM for brokers?
          </Typography>
          <Box display="flex" flexDirection="column" gap={3}>
            <Typography color="common.white" variant="body2" lineHeight={2.5}>
              The benefits of customizing a forex CRM are numerous. First and
              foremost, it allows brokers to streamline their operations and
              improve efficiency. By automating repetitive tasks and creating
              personalized workflows, brokers can save time and focus on more
              important tasks like client acquisition and retention.
            </Typography>

            <Typography color="common.white" variant="body2" lineHeight={2.5}>
              Customization also enables brokers to provide better customer
              service. By adding custom fields that capture relevant information
              about clients, brokers can personalize their interactions with
              them. This helps build trust and loyalty, which are critical for
              long-term success in the forex industry.
            </Typography>
            <Typography color="common.white" variant="body2" lineHeight={2.5}>
              Another benefit of customization is that it allows brokers to
              integrate third-party tools into their CRM system. For example,
              they can integrate trading platforms like MataTrader or cTrader
              into their CRM so that they can monitor trades in real-time and
              provide better support to clients.
            </Typography>
          </Box>
        </Box>
        <Box>
          <Typography
            textAlign="left"
            color="common.white"
            variant="h6"
            pb={6}
            pl={5}
          >
            What are the benefits of customizing forex CRM for brokers?
          </Typography>
          <Typography
            textAlign="left"
            variant="body2"
            color="common.white"
            pb={4}
            pl={5}
          >
            Here are some ways brokers can customize their forex CRM
          </Typography>

          <Box
            component="ol"
            sx={{ paddingInlineStart: "0" }}
            display="flex"
            flexDirection="column"
            gap={2.5}
          >
            <ListItemStyle2 data-value="1">
              <Box display="flex" flexDirection="column" gap={1}>
                <Typography variant="body2" color="common.white">
                  Customize dashboards
                </Typography>
                <Typography variant="body2" color="common.white">
                  Brokers can create customized dashboards that display the most
                  important information they need to see at a glance. This could
                  include real-time data on trades, account balances, and client
                  activity
                </Typography>
              </Box>
            </ListItemStyle2>
            <ListItemStyle2 data-value="2">
              <Box display="flex" flexDirection="column" gap={1}>
                <Typography variant="body2" color="common.white">
                  Automated workflows
                </Typography>
                <Typography variant="body2" color="common.white">
                  Brokers can set up automated workflows that streamline
                  processes such as account opening, KYC verification, and
                  deposit/withdrawal requests. This saves time and reduces the
                  risk of errors
                </Typography>
              </Box>
            </ListItemStyle2>
            <ListItemStyle2 data-value="3">
              <Box display="flex" flexDirection="column" gap={1}>
                <Typography variant="body2" color="common.white">
                  Integration with other systems
                </Typography>
                <Typography variant="body2" color="common.white">
                  Brokers can integrate their CRM system with other tools they
                  use such as trading platforms, payment gateways, and marketing
                  automation software. This ensures all data is synced across
                  system and reduces manual data entry
                </Typography>
              </Box>
            </ListItemStyle2>
            <ListItemStyle2 data-value="4">
              <Box display="flex" flexDirection="column" gap={1}>
                <Typography variant="body2" color="common.white">
                  Customized reports
                </Typography>
                <Typography variant="body2" color="common.white">
                  Brokers can create customized reports that provide insights
                  into client behaviour, trading patterns, and profitability.
                  This helps them make informed decisions about how to improve
                  their services
                </Typography>
              </Box>
            </ListItemStyle2>
            <ListItemStyle2 data-value="5">
              <Box display="flex" flexDirection="column" gap={1}>
                <Typography variant="body2" color="common.white">
                  Personalized communication
                </Typography>
                <Typography variant="body2" color="common.white">
                  Brokers can use their CRM system to send personalized
                  communication to clients based on their trading activity or
                  interests. This could include targeted marketing campaigns or
                  alerts about market news that may affect their trades
                </Typography>
                <Typography variant="body2" color="common.white">
                  Customizing a forex CRM system requires expertise in both
                  technology and finance. Brokers should with a reputable
                  provider who understands the unique needs of the forex company
                  and experience in developing customized solutions
                </Typography>
              </Box>
            </ListItemStyle2>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default CustomizingForexCrmContent;
