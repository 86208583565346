import Footer from "../Layout/Footer";
import ContactUsComponent from "../Layout/ContactUs";
import Header from "../Layout/Header";

const ContactUs = () => {
  return (
    <>
      <Header isHome={false} />
      <ContactUsComponent backgroundImage="home/FOOTER_BANNER.png" />
      <Footer />
    </>
  );
};

export default ContactUs;
