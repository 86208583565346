import { Box, Typography } from "@mui/material";
import { useGetMediaQueryUp } from "../../../hooks/useGetMediaQuery";

const BrokerFocused = () => {
  const isMdUp = useGetMediaQueryUp("md");

  return (
    <>
      <Box
        px={{ xs: 2, lg: 32.5 }}
        display="flex"
        flexDirection="column"
        gap={6}
      >
        <Box
          position="absolute"
          left={0}
          width="100%"
          backgroundColor="secondary.main"
          zIndex={-1}
          sx={{
            height: { lg: "572px", md: "470px", sm: "417px", xs: "433px" },
          }}
        />
        <Box display="flex" flexDirection="column" gap={1}>
          {isMdUp ? (
            <Typography
              textAlign="center"
              pt={12}
              color="primary.main"
              variant="h3"
            >
              Broker-focused CRM
            </Typography>
          ) : (
            <Typography
              textAlign="center"
              pt={12}
              color="primary.main"
              variant="h5"
            >
              Broker-focused CRM
            </Typography>
          )}

          <Typography textAlign="center" color="text.lightgray" variant="body2">
            We are powered by MT4, MT5, cTrader, & Vertex trading platform
          </Typography>
        </Box>
        <Box component="img" src="/Images/home/MT4.png" />
      </Box>
    </>
  );
};

export default BrokerFocused;
