import { Box, Button, Grid, Typography } from "@mui/material";

const CRM = () => {
  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <Grid container spacing={6}>
        <Grid item md={12} lg={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 5,
              justifyContent: "center",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <Typography color="text.lightgray" variant="h5">
                CRM System
              </Typography>
              <Typography color="text.lightgray" variant="body2">
                Introducing our cutting-edge CRM System Dashboard – your
                ultimate solution for streamlined administrative and support
                operations within the realm of forex brokerage. Designed with
                utmost user-friendliness in mind, our comprehensive control
                panel empowers you with complete oversight. Seamlessly manage
                client accounts, effortlessly process deposits and withdrawals,
                track trades with precision, ensure rigorous compliance
                adherence, effortlessly generate insightful reports, and
                maintain vigilant risk management. With the pivotal role that a
                proficient back-office plays in the success of a forex
                brokerage, our system stands as your guarantee for efficient
                operations and the delivery of impeccable service to your
                clients.
              </Typography>
            </Box>
            <Box>
              <Button
                variant="contained"
                color="warning"
                sx={{
                  borderRadius: 2,
                }}
              >
                <Typography
                  sx={{
                    textAlign: "center",
                  }}
                  color="primary.main"
                >
                  Live Demo
                </Typography>
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item md={12} lg={6} display="flex" alignItems="center">
          <Box
            component="img"
            src="/Images/home/CRM_Request.png"
            sx={{ width: "100%", borderRadius: 2 }}
            boxShadow={20}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CRM;
