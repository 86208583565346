import { Box, Grid, Typography } from "@mui/material";
import BackOfficePanelItem from "../BackOfficePanel/BackOfficePanelItem";
import { useGetMediaQueryUp } from "../../../hooks/useGetMediaQuery";

const TradersRoom = () => {
  const isMdUp = useGetMediaQueryUp("sm");

  return (
    <>
      {isMdUp ? (
        <Box px={{ xs: 6, lg: 32.5 }}>
          <Box position="relative" ml={{ xs: 3, lg: 5 }}>
            <Box
              sx={{
                backgroundColor: "secondary.main",
                borderTopLeftRadius: 12,
                borderBottomLeftRadius: 12,
              }}
              ml={4.75}
              p={5}
              display="flex"
              flexDirection="column"
              gap={4}
              boxShadow="-7px 7px #c1e3e6"
            >
              <Grid container>
                <Grid item md={12} lg={6} />
                <Grid item md={12} lg={6}>
                  <Typography variant="h3" color="primary.main">
                    Trader's Room
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item md={12} lg={6}>
                  <Box display="flex" alignItems="center" height="100%">
                    <Box
                      component="img"
                      src="/Images/home/BoxPortal.png"
                      sx={{ width: "100%" }}
                    />
                  </Box>
                </Grid>
                <Grid item md={12} lg={6}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 3,
                    }}
                  >
                    <BackOfficePanelItem
                      title="Easy Client Management"
                      content="Every component of our CRM system is developed for the need of a Forex broker."
                      fontColor="primary.main"
                      imageColor="primary.main"
                    />
                    <BackOfficePanelItem
                      title="Easy Client Management"
                      content="Every component of our CRM system is developed for the need of a Forex broker."
                      fontColor="primary.main"
                      imageColor="primary.main"
                    />
                    <BackOfficePanelItem
                      title="Easy Client Management"
                      content="Every component of our CRM system is developed for the need of a Forex broker."
                      fontColor="primary.main"
                      imageColor="primary.main"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box
              backgroundColor="secondary.main"
              height={180}
              width={50}
              sx={{ transform: "rotate(25deg)" }}
              boxShadow="-5px 10px #c1e3e6"
              position="absolute"
              top={30}
              left={0}
            />
          </Box>
        </Box>
      ) : (
        <>
          <Box
            sx={{
              backgroundColor: "secondary.main",
            }}
            p={5}
            display="flex"
            flexDirection="column"
            gap={4}
            boxShadow="-7px 7px #c1e3e6"
          >
            <Grid container>
              <Grid item md={12} lg={6} />
              <Grid item md={12} lg={6}>
                <Typography variant="h3" color="primary.main">
                  Trader's Room
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item md={12} lg={6}>
                <Box display="flex" alignItems="center" height="100%">
                  <Box
                    component="img"
                    src="/Images/home/BoxPortal.png"
                    sx={{ width: "100%" }}
                  />
                </Box>
              </Grid>
              <Grid item md={12} lg={6}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 3,
                  }}
                >
                  <BackOfficePanelItem
                    title="Easy Client Management"
                    content="Every component of our CRM system is developed for the need of a Forex broker."
                    fontColor="primary.main"
                    imageColor="primary.main"
                  />
                  <BackOfficePanelItem
                    title="Easy Client Management"
                    content="Every component of our CRM system is developed for the need of a Forex broker."
                    fontColor="primary.main"
                    imageColor="primary.main"
                  />
                  <BackOfficePanelItem
                    title="Easy Client Management"
                    content="Every component of our CRM system is developed for the need of a Forex broker."
                    fontColor="primary.main"
                    imageColor="primary.main"
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </>
  );
};

export default TradersRoom;
