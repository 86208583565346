import { Box, Menu, MenuItem, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const HeaderSubMenu = ({ open, handleClose, anchorEl, subMenu }) => {
  const navigate = useNavigate();

  const handleClick = (path) => {
    navigate(path);
  };

  return (
    <Menu
      anchorEl={anchorEl}
      id="header-sub-menu"
      open={open}
      onClick={handleClose}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <Box py={1}>
        {subMenu &&
          subMenu.length &&
          subMenu.map((item, index) => {
            return (
              <MenuItem
                key={index}
                onClick={() => handleClick(item.path)}
                sx={{ px: 4 }}
              >
                <Typography color="primary.main">{item.name}</Typography>
              </MenuItem>
            );
          })}
      </Box>
    </Menu>
  );
};

export default HeaderSubMenu;
