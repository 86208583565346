import { Box, Button, Grid, Typography } from "@mui/material";

const IbPortal = () => {
  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <Grid container spacing={6}>
        <Grid item md={12} lg={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 5,
              justifyContent: "center",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <Typography color="text.lightgray" variant="h5">
                IB Portal
              </Typography>
              <Typography color="text.lightgray" variant="body2">
                Reinforce your brokerage with a dynamic and versatile IB portal,
                designed to elevate your partner relationships to new heights.
                This comprehensive IB management system equips your partners
                with a suite of essential tools. They can effortlessly oversee
                their referred clients, establish diverse commission structures,
                monitor performance through detailed real-time reports, and
                amplify your client base. Our sophisticated IB/Affiliate
                software streamlines commission pay-outs and partner management,
                ensuring efficiency and accuracy.
              </Typography>
              <Typography color="text.lightgray">
                Elevate your partnership game with Finitic – where possibilities
                become partnerships, and partnerships become enduring success.
              </Typography>
            </Box>
            <Box>
              <Button
                variant="contained"
                color="warning"
                sx={{
                  borderRadius: 2,
                }}
              >
                <Typography
                  sx={{
                    textAlign: "center",
                  }}
                  color="primary.main"
                >
                  Live Demo
                </Typography>
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item md={12} lg={6} display="flex" alignItems="center">
          <Box
            component="img"
            src="/Images/home/IB_Portal.png"
            sx={{ width: "100%", borderRadius: 2 }}
            boxShadow={20}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default IbPortal;
