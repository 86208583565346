import styled from "@emotion/styled";

export const ListItemStyle2 = styled("li")({
  listStyle: "none",
  display: "flex",
  alignItems: "baseline",
  "&::before": {
    content: "attr(data-value)",
    fontWeight: 650,
    fontSize: 20,
    color: "#f89622",
    padding: "0px 5px 2px 5px ",
    marginRight: 18,
    lineHeight: 1,
  },
});
