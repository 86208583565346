import { Box, Button, Typography } from "@mui/material";

const ForexCrm = () => {
  return (
    <Box
      component="section"
      display="flex"
      flexDirection="column"
      py={6}
      gap={4}
      sx={{
        backgroundImage: "url(/Images/common/FOREX_CRM1.jpg)",
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        width="100%"
      >
        <Typography
          textAlign="center"
          variant="h3"
          fontWeight={500}
          color="common.white"
        >
          FOREX CRM
        </Typography>
        <Typography textAlign="center" variant="h6" color="common.white">
          START YOUR OWN BROKERAGE
        </Typography>
      </Box>

      <Box display="flex" justifyContent="center">
        <Button
          variant="contained"
          color="warning"
          sx={{
            borderRadius: 6,
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
            }}
            color="primary.main"
          >
            BUY NOW
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default ForexCrm;
