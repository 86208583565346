import { Box, Typography } from "@mui/material";

const BackOfficePanelItem = ({ title, content, fontColor, imageColor }) => {
  return (
    <Box display="flex" gap={2.5}>
      <Box
        height={{ xs: 16, md: 32 }}
        sx={{
          backgroundColor: imageColor,
          borderRadius: "50%",
          p: { xs: 1.5, md: 3 },
        }}
      >
        <Box
          component="img"
          src="/Images/home/FI_LOGO.png"
          height={{ xs: 16, md: 32 }}
          width={{ xs: 16, md: 32 }}
          sx={{
            filter:
              imageColor === "warning.main"
                ? "invert(100%) sepia(100%) saturate(0%) hue-rotate(343deg) brightness(102%) contrast(103%)"
                : "",
          }}
        />
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <Typography color={fontColor} variant="h6">
          {title}
        </Typography>
        <Typography color={fontColor}>{content}</Typography>
      </Box>
    </Box>
  );
};

export default BackOfficePanelItem;
