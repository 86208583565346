import { Box, Grid, Typography } from "@mui/material";
import FeaturesItem from "./FeaturesItem";
import { useGetMediaQueryUp } from "../../../hooks/useGetMediaQuery";

const Features = () => {
  const isMdUp = useGetMediaQueryUp("sm");

  return (
    <Box px={{ xs: 2, lg: 32.5 }} display="flex" flexDirection="column" gap={6}>
      {isMdUp ? (
        <Typography variant="h3" textAlign="center" color="secondary.main">
          Features That Make Broker Management Easy
        </Typography>
      ) : (
        <Typography variant="h5" textAlign="center" color="secondary.main">
          Features That Make Broker Management Easy
        </Typography>
      )}

      <Grid container spacing={8}>
        <Grid item xs={12} md={6} lg={4}>
          <FeaturesItem
            title="Scalable Platform"
            content="Designed to cater to the needs of a forex broker, offering comprehensive
        tools to manage customer relationships, trading platforms, and
        operations."
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <FeaturesItem
            title="Account Management"
            content="Designed to cater to the needs of a forex broker, offering comprehensive
        tools to manage customer relationships, trading platforms, and
        operations."
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <FeaturesItem
            title="Easy to Use"
            content="Designed to cater to the needs of a forex broker, offering comprehensive
        tools to manage customer relationships, trading platforms, and
        operations."
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <FeaturesItem
            title="Scalable Platform"
            content="Designed to cater to the needs of a forex broker, offering comprehensive
        tools to manage customer relationships, trading platforms, and
        operations."
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <FeaturesItem
            title="Account Management"
            content="Designed to cater to the needs of a forex broker, offering comprehensive
        tools to manage customer relationships, trading platforms, and
        operations."
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <FeaturesItem
            title="Easy to Use"
            content="Designed to cater to the needs of a forex broker, offering comprehensive
        tools to manage customer relationships, trading platforms, and
        operations."
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Features;
