import { Helmet } from "react-helmet-async";
import ReportingAndAnalyticsContent from "../../components/Blogs/ReportingAndAnalytics";
import ContactUs from "../../components/Layout/ContactUs";
import Footer from "../../components/Layout/Footer";
import Header from "../../components/Layout/Header";

const ReportingAndAnalytics = () => {
  return (
    <>
      <Helmet>
        <title>Reporting And Analytics</title>
        <meta name="description" content="Reporting And Analytics" />
      </Helmet>
      <Header isHome={false} />
      <ReportingAndAnalyticsContent />
      <ContactUs backgroundImage="blogs/CONTACTUS_BANNER2.png" />
      <Footer />
    </>
  );
};

export default ReportingAndAnalytics;
