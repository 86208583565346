import { Box, Grid, IconButton, Typography } from "@mui/material";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import { footerAboutUsTabs, footerProducts } from "../../constants/footerBar";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <Box
        component="section"
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        gap={6}
        px={3}
        sx={{
          backgroundImage: "url(/Images/layout/FINITIC_FOOTER1.png)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
          pt: 8,
        }}
      >
        <Box display="flex" gap={20}>
          <Grid container spacing={5}>
            <Grid item xs={6} md={3}>
              <Box
                component="img"
                src="/Images/layout/FINITIC.png"
                height="63px"
                width="137px"
                pl={{ xs: 0, lg: 22.88 }}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <Box display="flex" flexDirection="column" gap={1.5}>
                <Typography color="primary.main" variant="h5">
                  Products
                </Typography>
                {footerProducts.map((tab, index) => (
                  <Box key={index} display="flex" flexDirection="column">
                    <Typography color="warning.main" variant="h6">
                      {tab.name}
                    </Typography>
                    {tab.content.map((cont, index) => (
                      <Typography
                        key={index}
                        color="primary.main"
                        variant="body2"
                      >
                        {cont.name}
                      </Typography>
                    ))}
                  </Box>
                ))}
              </Box>
            </Grid>
            <Grid item xs={6} md={3}>
              <Box display="flex" flexDirection="column" gap={1}>
                <Typography color="primary.main" variant="h5">
                  ABOUT US
                </Typography>
                {footerAboutUsTabs.map((tab, index) => (
                  <Box
                    key={index}
                    component={Link}
                    to={tab.path}
                    sx={{ textDecoration: "none" }}
                  >
                    <Typography color="primary.main" variant="body2">
                      {tab.name}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Grid>
            <Grid item xs={6} md={3}>
              <Box display="flex" flexDirection="column">
                <Typography color="primary.main" variant="h5" mb={2}>
                  GET IN TOUCH
                </Typography>
                <Typography display="flex" color="warning.main" variant="body2">
                  info@finitic.com&nbsp;
                  <Typography color="primary.main" variant="body2">
                    email us
                  </Typography>
                </Typography>
                <Typography display="flex" color="warning.main" variant="body2">
                  +971 45689835&nbsp;
                  <Typography color="primary.main" variant="body2">
                    call us
                  </Typography>
                </Typography>
                <Typography display="flex" color="primary.main" variant="body2">
                  Find us via&nbsp;
                  <Typography color="warning.main" variant="body2">
                    <a
                      style={{ textDecoration: "none", color: "inherit" }}
                      href="https://maps.app.goo.gl/ci2mdcPGEnVvKouu8"
                    >
                      Google Map
                    </a>
                  </Typography>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box display="flex" flexDirection="column">
          <Typography
            color="primary.main"
            px={{ xs: 0, lg: 22.88 }}
            textAlign="center"
          >
            We are a leading brokerage technology company, committed to offering
            a wide range of products to help traders navigate the world of forex
            around the world. Our team of experts is dedicated to providing the
            best possible service with a focus on innovation, reliability, and
            transparency with the latest technology to make informed decisions
            and achieve the financial goals of our clients. We are committed to
            providing exceptional service.
          </Typography>
          <Box py={10} pl={{ xs: 0, lg: 22.88 }}>
            <IconButton>
              <Box
                component="img"
                src="/Images/layout/FB.png"
                height="24px"
                sx={{
                  filter:
                    " invert(79%) sepia(29%) saturate(7165%) hue-rotate(345deg) brightness(103%) contrast(94%)",
                }}
              />
            </IconButton>
            <IconButton>
              <TwitterIcon color="warning" />
            </IconButton>
            <IconButton>
              <Box
                component="img"
                src="/Images/layout/G+.png"
                height="24px"
                sx={{
                  filter:
                    " invert(79%) sepia(29%) saturate(7165%) hue-rotate(345deg) brightness(103%) contrast(94%)",
                }}
              />
            </IconButton>
            <IconButton>
              <InstagramIcon color="warning" />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Typography
        variant="body2"
        backgroundColor="black"
        textAlign="center"
        color="warning.main"
      >
        Finitic© 2023 All rights reserved.
      </Typography>
    </>
  );
};

export default Footer;
