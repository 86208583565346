import {
  Box,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  MenuItem,
  Typography,
} from "@mui/material";
import { headerTabs } from "../../constants/headerTabs";
import { KeyboardArrowDown, KeyboardArrowLeft } from "@mui/icons-material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const SideBar = ({ activeTab, toggleDrawer, handleClick }) => {
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  const handleButtonClick = (event) => {
    event.stopPropagation();
    setOpen((prev) => !prev);
  };

  const handleSubMenuItemClick = (path) => {
    navigate(path);
  };

  return (
    <Box
      sx={{ width: 290 }}
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {headerTabs.map((tab, index) => (
          <>
            <ListItem key={index} disablePadding>
              <ListItemButton
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  px: 3.5,
                }}
                onClick={(event) => {
                  if (tab.isSubMenu) handleButtonClick(event);
                  handleClick(index, tab.path, true)(event);
                }}
              >
                <Typography
                  color={index === activeTab ? "warning.main" : "primary.main"}
                >
                  {tab.name}
                </Typography>
                {tab.isSubMenu &&
                  (open ? (
                    <KeyboardArrowDown
                      color={index === activeTab ? "warning" : "primary"}
                    />
                  ) : (
                    <KeyboardArrowLeft
                      color={index === activeTab ? "warning" : "primary"}
                    />
                  ))}
              </ListItemButton>
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit sx={{ px: 2 }}>
              <List component="div" disablePadding>
                {tab.subMenu &&
                  tab.subMenu.length > 0 &&
                  tab.subMenu.map((item, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => handleSubMenuItemClick(item.path)}
                      sx={{ px: 4 }}
                    >
                      <Typography color="primary.main">{item.name}</Typography>
                    </MenuItem>
                  ))}
              </List>
            </Collapse>
          </>
        ))}
      </List>
    </Box>
  );
};

export default SideBar;
