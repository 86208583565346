import { Box, Button, Grid, Typography } from "@mui/material";

const HybridCRM = () => {
  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <Grid container spacing={6}>
        <Grid item md={12} lg={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 5,
              justifyContent: "center",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <Typography color="text.lightgray" variant="h5">
                Hybrid CRM Systems
              </Typography>
              <Typography color="text.lightgray" variant="body2">
                Proudly we can now announce that we have invented the first
                systems and the only for the main time , that can give your
                client s and you to full control over Crypto Currency & Physical
                Gold & Forex investments with one client portal that you have
                full control over it with your Hybrid CRM from one dashboard , I
                dose not stope here !! this systems can give you ability to
                Deposit/Withdraw or Internally transfer funds from one to
                another and much more controls that will boost your business
                efficiency and profitability.
              </Typography>
            </Box>
            <Box>
              <Button
                variant="contained"
                color="warning"
                sx={{
                  borderRadius: 2,
                }}
              >
                <Typography
                  sx={{
                    textAlign: "center",
                  }}
                  color="primary.main"
                >
                  Live Demo
                </Typography>
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item md={12} lg={6} display="flex" alignItems="center">
          <Box
            component="img"
            src="/Images/home/Gold_Portal.png"
            sx={{ width: "100%", borderRadius: 2 }}
            boxShadow={20}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default HybridCRM;
