export const headerTabs = [
  {
    name: "Home Page",
    path: "/home",
    isSubMenu: false,
    subMenu: [],
  },
  {
    name: "About Us",
    path: "/about-us",
    isSubMenu: false,
    subMenu: [],
  },
  {
    name: "Products",
    path: "",
    isSubMenu: true,
    subMenu: [
      {
        name: "CRM System",
        path: "/product/crm-system",
      },
      {
        name: "Client Portal",
        path: "/product/client-portal",
      },
      {
        name: "IB Portal",
        path: "/product/ib-portal",
      },
      {
        name: "Physical Gold",
        path: "/product/physical-gold",
      },
      {
        name: "Hybrid CRM",
        path: "/product/hybrid-crm",
      },
    ],
  },
  {
    name: "Blogs",
    path: "",
    isSubMenu: true,
    subMenu: [
      {
        name: "Customizing Forex CRM",
        path: "/blogs/customizing-forex-crm",
      },
      {
        name: "Metatrader 4 & Metatrader 5",
        path: "/blogs/mt4-and-mt5",
      },
      {
        name: "Reporting & Analytics",
        path: "/blogs/reporting-and-analytics",
      },
      {
        name: "Important of Forex",
        path: "/blogs/important-of-forex",
      },
    ],
  },
  {
    name: "Contact Us",
    path: "/contact-us",
    isSubMenu: false,
    subMenu: [],
  },
  // {
  //   name: "Our Team",
  //   path: "/our-team",
  //   isSubMenu: false,
  //   subMenu: [],
  // },
  // {
  //   name: "Privacy Policy",
  //   path: "/privacy-policy",
  //   isSubMenu: false,
  //   subMenu: [],
  // },
];
