export const footerAboutUsTabs = [
  //   {
  //     name: "Our Team",
  //     path: "/our-team",
  //   },
  {
    name: "Customizing Forex CRM",
    path: "/blogs/customizing-forex-crm",
  },
  {
    name: "Metatrader 4 & Metatrader 5",
    path: "/blogs/mt4-and-mt5",
  },
  {
    name: "Reporting & Analytics",
    path: "/blogs/reporting-and-analytics",
  },
  {
    name: "Important of Forex",
    path: "/blogs/important-of-forex",
  },
  {
    name: "Privacy Policy",
    path: "/privacy-policy",
  },
];

export const footerProducts = [
  {
    name: "Forex",
    content: [
      {
        name: "Forex CRM / Liquidity Provider / PAMM/MAM / Client Portal / IB Portal / Risk Management / Back-office Solution",
        path: "https://my.finitic.com/login",
      },
    ],
  },
  {
    name: "Physical Gold",
    content: [
      {
        name: "CRM",
        path: "https://my.finitic.com/login",
      },
    ],
  },
  {
    name: "Crypto",
    content: [
      {
        name: "CRM",
        path: "https://my.finitic.com/register/forex/live",
      },
    ],
  },
  {
    name: "Hosting Solution",
    content: [
      {
        name: " MetaTrader 4/5",
        path: "https://my.finitic.com/login",
      },
      {
        name: "MT4/5 Administrative Services",
        path: "https://my.finitic.com/login",
      },
      {
        name: "Hosting Server",
        path: "https://my.finitic.com/login",
      },
    ],
  },
];
