import { Box, Button, Grid, Typography } from "@mui/material";
import { useGetMediaQueryUp } from "../../../hooks/useGetMediaQuery";

const BrokerManagement = () => {
  const isMdUp = useGetMediaQueryUp("md");

  return (
    <Box
      px={{ xs: 2, lg: 32.5 }}
      pt={11.5}
      display="flex"
      flexDirection="column"
      gap={6}
    >
      {isMdUp ? (
        <Typography variant="h2" textAlign="center" color="secondary.main">
          Most intuitive & rich features packed Forex CRM ever built for Forex
          Broker
        </Typography>
      ) : (
        <Typography variant="h6" textAlign="center" color="secondary.main">
          Most intuitive & rich features packed Forex CRM ever built for Forex
          Broker
        </Typography>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: { lg: "row", xs: "column" },
        }}
      >
        <Grid container spacing={6}>
          <Grid item md={12} lg={6}>
            <Box
              component="img"
              src="/Images/home/CRM_Request.png"
              sx={{ width: "100%", borderRadius: 2 }}
              boxShadow={20}
            />
          </Grid>
          <Grid item md={12} lg={6}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                justifyContent: "center",
              }}
            >
              {isMdUp ? (
                <Typography
                  variant="h4"
                  sx={{
                    textAlign: { lg: "left", xs: "center" },
                  }}
                >
                  Broker Management on the go
                </Typography>
              ) : (
                <Typography
                  variant="h5"
                  sx={{
                    textAlign: { lg: "left", xs: "center" },
                  }}
                >
                  Broker Management on the go
                </Typography>
              )}

              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography color="text.lightgray">Trading Summary</Typography>
                <Typography variant="body2" color="disabled.main">
                  Watch every trade activity right from your mobile screen
                </Typography>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography color="text.lightgray">Quick Access</Typography>
                <Typography variant="body2" color="disabled.main">
                  Instant action & info button for agile decision-making right
                  from your palm.
                </Typography>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography color="text.lightgray">
                  Made For Performance
                </Typography>
                <Typography variant="body2" color="disabled.main">
                  Most intuitive & performance-centric Forex CRM in the market.
                </Typography>
              </Box>
              <Box>
                <Button
                  variant="contained"
                  color="warning"
                  sx={{
                    borderRadius: 5.25,
                    mt: 4,
                  }}
                >
                  <Typography
                    sx={{
                      textAlign: "center",
                    }}
                    color="primary.main"
                  >
                    Live Demo
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default BrokerManagement;
