import { Box, Button, Grid, Select, Typography } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { TextFieldStyle } from "../../../theme/customStyle/TextFieldStyle";
import { MenuItemStyle } from "../../../theme/customStyle/MenuItemStyle";
import axios from "axios";

const ContactUs = ({ backgroundImage }) => {
  const validationSchema = yup.object({
    name: yup.string().required("Name is required"),
    phone: yup.string().required("Phone is required"),
    email: yup
      .string()
      .email("Enter a valid email")
      .required("Email is required"),
    message: yup.string().required("Message is required"),
    subject: yup.string().required("Subject is required"),
  });

  const { handleSubmit, handleChange, handleBlur, errors, touched, values } =
    useFormik({
      initialValues: {
        name: "",
        phone: "",
        email: "",
        message: "",
        subject: "",
      },

      onSubmit: (values) => {
        console.log(values);
        values.preventDefault();
        axios(options)
            .then(res => {
                window.location='/home';
            })
            .catch(e => console.log(e.message))
      },
      validationSchema: validationSchema,
    });

    const options = {
      url: '',
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: values
    };

  return (
    <Box
      component="section"
      px={{ xs: 12, lg: 63 }}
      py={8}
      display="flex"
      flexDirection="column"
      gap={6}
      sx={{
        backgroundImage: `url(/Images/${backgroundImage})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
      }}
    >
      <Typography
        variant="h4"
        sx={{
          textAlign: "center",
        }}
        color="primary.main"
      >
        Contact us
      </Typography>
      <Box component="form" onSubmit={handleSubmit}>
        <Grid container spacing={4}>
          {/* <Grid item xs={12}>
            <Select
              labelId="demo-simple-select-label"
              id="product"
              name="product"
              fullWidth
              placeholder="Click to select product"
              label="Click to select product"
              defaultValue={1}
              onChange={handleChange}
              sx={{ backgroundColor: "primary.main" }}
            >
              <MenuItemStyle value={1}>Product 1</MenuItemStyle>
              <MenuItemStyle value={2}>Product 2</MenuItemStyle>
              <MenuItemStyle value={3}>Product 3</MenuItemStyle>
            </Select>
          </Grid> */}
          <Grid item xs={12}>
            <TextFieldStyle
              id="name"
              name="name"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.name}
              variant="outlined"
              placeholder="Your name"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextFieldStyle
              id="phone"
              name="phone"
              value={values.phone}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.phone}
              fullWidth
              multiline
              //minRows={5}
              placeholder="Your phone"
            />
          </Grid>
          <Grid item xs={12}>
            <TextFieldStyle
              id="email"
              name="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.email}
              variant="outlined"
              placeholder="Your Email"
              fullWidth
              helperText={touched.email && errors.email}
            />
          </Grid>
          <Grid item xs={12}>
            <TextFieldStyle
              id="message"
              name="message"
              value={values.message}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.message}
              variant="outlined"
              placeholder="Your message"
              multiline
              minRows={5}
              fullWidth
              helperText={touched.message && errors.message}
            />
          </Grid>
          <Grid item xs={12}>
            <TextFieldStyle
              id="subject"
              name="subject"
              value={values.subject}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.subject}
              variant="outlined"
              placeholder="Your subject"
              multiline
              minRows={5}
              fullWidth
              helperText={touched.subject && errors.subject}
            />
          </Grid>
          <Grid item xs={3}>
            <Button
              fullWidth
              sx={{ color: "primary.main" }}
              color="warning"
              type="submit"
              variant="contained"
            >
              Send Now
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ContactUs;
