import { Tab, Tabs, styled } from "@mui/material";
import { useGetMediaQueryDown } from "../../hooks/useGetMediaQuery";

export const TabsStyle = styled(Tabs)(({ theme }) => ({
  width: "100%",
  marginTop: 48,
  marginBottom: 48,
  display: useGetMediaQueryDown("md") ? "none" : "flex",
  "& .MuiTabs-flexContainer": {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: theme.palette.warning.main,
  },
}));

export const TabStyle = styled(Tab)(({ theme }) => ({
  textTransform: "none",
  fontWeight: theme.typography.h6.fontWeight,
  fontSize: theme.typography.h6.fontSize,
  color: theme.palette.primary.main,
  display: "flex",
  flexDirection: "row-reverse",
  "&.Mui-selected": {
    color: theme.palette.warning.main,
  },
  "&.Mui-focusVisible": {
    backgroundColor: theme.palette.warning.main,
  },
}));
