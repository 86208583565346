import { Box, Button, Grid, Typography } from "@mui/material";

const Gold = () => {
  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <Grid container spacing={6}>
        <Grid item md={12} lg={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 5,
              justifyContent: "center",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <Typography color="text.lightgray" variant="h5">
                Physical Gold
              </Typography>
              <Typography color="text.lightgray" variant="body2">
                take your business to the next level and enhance your provided
                services by adding a Mind-blowing Services that will defiantly
                attract any investors will hear about , including Finitic
                Physical Gold system to your business tools pack will give you
                the ability to offer , physical gold buying / selling / safe
                storing or even doorstep delivery all of this will be as easy as
                few clicks not only that ,Finitic Physical gold system is
                equipped with internal E-Wallets that Clients can deposit
                directly in to it to use it for direct fast purchase.
              </Typography>
            </Box>
            <Box>
              <Button
                variant="contained"
                color="warning"
                sx={{
                  borderRadius: 2,
                }}
              >
                <Typography
                  sx={{
                    textAlign: "center",
                  }}
                  color="primary.main"
                >
                  Live Demo
                </Typography>
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid item md={12} lg={6} display="flex" alignItems="center">
          <Box
            component="img"
            src="/Images/home/Gold_Portal.png"
            sx={{ width: "100%", borderRadius: 2 }}
            boxShadow={20}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Gold;
