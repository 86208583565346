import { Box, Typography } from "@mui/material";

const WhoWeAre = () => {
  return (
    <Box
      py={12}
      px={{ xs: 12, lg: 32.5 }}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundImage: "url(/Images/aboutUs/WHO_WE_ARE.png)",
        backgroundRepeat: "no-repeat",
        backgroundsize: "cover",
        backgroundPosition: "bottom",
        backgroundAttachment: "fixed",
        gap: 8,
      }}
    >
      <Box>
        <Typography textAlign="center" variant="h6">
          Welcome to FINITIC - Where Innovation Meets Financial Expertise
        </Typography>
        <Typography pt={2} textAlign="justify" font-family="sans-serif">
          At FINITIC, we're not just another fintech company; we're a group of
          visionary developers driven by a relentless passion for thinking
          beyond boundaries. Our journey began with a simple yet powerful goal:
          to revolutionize the way brokers and traders manage confidential
          information while maintaining unparalleled organization.
        </Typography>
      </Box>
      <box>
        <Typography textAlign="center" variant="h6">
          Our Flagship Solution: Empowering Brokers & Traders
        </Typography>
        <Typography pt={2} textAlign="justify" font-family="sans-serif">
          FINITIC products are not just tools; they are the embodiment of
          innovation and reliability. Designed with an unwavering commitment to
          data security, our solutions redefine the standard for safeguarding
          sensitive information in the fast-paced world of finance. With our
          out-of-the-box thinking, we've meticulously crafted a suite of
          products that empower startups and established brokerage firms alike.
        </Typography>
      </box>
      <box>
        <Typography textAlign="center" variant="h6">
          Pioneering Technological Growth in Finance
        </Typography>
        <Typography pt={2} textAlign="justify" font-family="sans-serif">
          In an era where technology is the bedrock of success, we're at the
          forefront of technological advancement in the financial market. Our
          unwavering focus centers on identifying and harnessing technologies
          that elevate your prospects of establishing, nurturing, and scaling
          your FX Company. From crafting a formidable online presence through
          web development to ensuring seamless operations via dedicated hosting
          servers, we've got your IT needs comprehensively covered.
        </Typography>
      </box>
      <box>
        <Typography textAlign="center" variant="h6">
          Trading Solutions Redefined
        </Typography>
        <Typography pt={2} textAlign="justify" font-family="sans-serif">
          Trading isn't just about transactions; it's about strategy, precision,
          and opportunity. We recognize this, and our cutting-edge trading
          solutions reflect our commitment to arming you with the tools you need
          to navigate the complexities of the financial world. Whether you're a
          startup aiming to make your mark or an established brokerage firm
          seeking to expand your horizons, our solutions are tailored to amplify
          your capabilities.
        </Typography>
      </box>
      <box>
        <Typography pt={2} textAlign="justify" font-family="sans-serif">
          Discover a new era of reliability, organization, and growth with
          FINITIC. Your financial journey is about to transcend the ordinary.
        </Typography>
      </box>
      <box>
        <Typography textAlign="left" variant="h6">
          Join the FINITIC Revolution
        </Typography>
        <Typography pt={2} textAlign="justify" font-family="sans-serif">
          Our team of forward-thinking developers isn't just a team – we're your
          partners in progress. We've built our foundation on innovation,
          integrity, and an unyielding dedication to empowering financial
          ventures of all sizes. As you explore our solutions and embrace our
          vision, you're not just adopting technology; you're joining the
          FINITIC revolution that's shaping the future of fintech.
        </Typography>
      </box>
      <Box
        display="flex"
        flexDirection={{ xs: "column", md: "row" }}
        justifyContent="space-between"
        gap={8}
        px={{ xs: 0, lg: 32.5 }}
      >
        <Box display="flex" flexDirection="column" gap={0.5}>
          <Typography variant="h6" color="warning.main">
            MISSION
          </Typography>
          <Typography variant="body2">
            Our mission is to empower forex brokers with the most advanced and
            user-friendly brokerage tech solutions, enabling them to provide
            exceptional customer experiences and achieve their business goals.
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" gap={0.5}>
          <Typography variant="h6" color="warning.main">
            VISION
          </Typography>
          <Typography variant="body2">
            Our vision is to be the leading provider of innovative brokerage
            tech solutions, driving the success of Forex brokers worldwide
            through cutting-edge technology.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default WhoWeAre;
